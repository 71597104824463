<template>
  <DataTable
    :value="data"
    tableStyle="min-width: 50rem"
    class="mt-2 clients-table max-h-[60vh]"
    scrollable
    scrollHeight="60vh"
    v-show="!loading && data.length > 0"

  >
    <Column field="id" header="ID"></Column>
    <Column field="code" header="Código"></Column>
    <Column field="type" header="Tipo">
      <template #body="slotProps">
        {{ slotProps.data.type.code }}
      </template>
    </Column>
    <Column field="type" header="Grupo">
      <template #body="slotProps">
        {{ slotProps.data.type.group }}
      </template>
    </Column>
    <Column field="issuerDocument" header="Emissor">
      <template #body="slotProps">
        <p
          style="min-width: 160px"
          v-tooltip.top="
            slotProps.data.issuerDocument?.replace(

              /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
              '$1.$2.$3/$4-$5'
            )
          "
        >
          {{
            slotProps.data.issuerDocument?.replace(
              /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
              "$1.$2.$3/$4-$5"
            )
          }}
        </p>
      </template>
    </Column>

    <Column header="Ações">
      <template #body="slotProps">
        <img
          src="@/assets/svgs/edit.svg"
          alt="Editar"
          class="cursor-pointer block mx-auto"
          @click="
            router.push({
              name: 'ativos-create-edit',
              params: { id: slotProps.data.id, mode: 'editar' },
            })
          "
        />
      </template>
    </Column>
  </DataTable>
  <TableLoading v-if="loading" />
  <NoResultsFound
    v-if="!loading && data.length === 0"
    @buttonClick="$emit('clearFilter')"
  />

  <Paginator
    class="mt-5"
    :totalRecords="paginatorData.totalItems"
    :rows="paginatorData.itemsPerPage"
    @page="($event) => $emit('pageEvent', $event)"
  ></Paginator>
</template>

<script setup lang="ts">
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Paginator from "primevue/paginator";
import NoResultsFound from "../NoResultsFound.vue";
import TableLoading from "../loading/TableLoading.vue";

import { Ativo } from "@/core/interfaces/Ativos";
import { MetaValues } from "@/core/interfaces/Customers";
import { PropType } from "vue";
import { vMaska } from "maska";

import { useRouter } from "vue-router";
const router = useRouter();
defineEmits(["pageEvent", "frameworkDetails", "clearFilter"]);

defineProps({
  data: {
    type: Object as PropType<Ativo[]>,
    required: true,
  },
  paginatorData: {
    type: Object as PropType<MetaValues>,
    required: true,
  },
  loading: {
    type: Boolean,
    required: false,
    default: true,
  },
});
</script>

//manter sem scoped
<style>
.p-column-title {
  margin: auto;
}

.p-datatable-table tbody tr {
  &:nth-child(even) {
    background-color: theme("colors.tableStripe.1");
  }

  td {
    text-align: center !important;
  }

  &:last-child {
    td {
      &:first-child {
        border-bottom-left-radius: 8px;
      }

      &:last-child {
        border-bottom-right-radius: 8px;
      }
    }
  }
}

.p-datatable-table thead th {
  background-color: theme("colors.primary.DEFAULT");
  color: theme("colors.white.DEFAULT");
  padding-top: 1rem;

  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
}
</style>
