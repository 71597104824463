<template>
  <div
    class="flex justify-start items-center px-5 py-2"
    :class="handleBg()"
    style="border-radius: 8px"
  >
    <img :src="getImg" alt="icon" class="mr-4 w-5" />
    <h2 class="font-medium xl:text-[1rem]">{{ message }}</h2>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps({
  message: {
    type: String,
    required: true,
  },
  status: {
    type: String,
    required: true,
  },
  isCompliant: {
    type: Boolean,
    required: true,
  },
  isCompliantWithoutTolerance: {
    type: Boolean,
    default: false,
  },
});

const handleBg = () => {
  console.log("Status:", props.status);
  console.log("isCompliant:", props.isCompliant);
  console.log(
    "isCompliantWithoutTolerance:",
    props.isCompliantWithoutTolerance
  );
  if (props.status === "failed" || !props.isCompliant) {
    return "bg-details-error text-red-600";
  } else if (props.isCompliant && !props.isCompliantWithoutTolerance) {
    return "bg-details-warning text-details-text-warning";
  } else {
    return "bg-details-success text-details-text-success";
  }
};

const getImg = computed(() => {
  let icon = "";

  if (props.status === "failed" || !props.isCompliant) {
    icon = "alert";
  } else if (props.isCompliant && !props.isCompliantWithoutTolerance) {
    icon = "warning-icon";
  } else {
    icon = "success-squared";
  }
  const url = new URL(`../../assets/svgs/${icon}.svg`, import.meta.url).href;

  return url;
});
</script>

<style>
.bg-details-success {
  background-color: #e6ffd0;
}
.bg-details-warning {
  background-color: #f5a62330;
}
</style>
