<template>
  <form
    @submit.prevent="handleFormSubmit"
    class="flex items-center justify-center w-full h-full relative shadow-sm"
  >
    <div
      class="bg-white form-wrapper rounded-lg flex items-center justify-center flex-col py-2 px-10 shadow-sm"
    >
      <div class="brand-wrapper pb-[42px] pt-[15px]">
        <img
          class="w-[137px]"
          src="../../assets/svgs/brand-md.svg"
          alt="Cyfle"
          srcset=""
        />
      </div>
      <div
        class="flex flex-col font-semibold text-[18px] gap-1 input-wrapper h-[78px] mb-[14px] login-form-input-group"
      >
        <label for="username" id="email-label" class="font-bold">E-mail</label>
        <InputText
          required
          type="email"
          v-model="email"
          class="global-input login-input placeholder-login"
          placeholder="email@example.com"
        />
      </div>
      <div
        class="flex flex-col font-semibold text-[18px] gap-1 input-wrapper h-[78px] mb-[14px] login-form-input-group"
      >
        <label for="password" id="password-label" class="font-bold"
          >Senha</label
        >
        <label
          id="password-input-wrapper-label"
          for="password"
          class="global-input login-input password-input"
        >
          <Password
            required
            id="password"
            name="password"
            v-model="password"
            class="w-full placeholder-login"
            :feedback="false"
            placeholder="Digite sua senha..."
            toggleMask
          />
        </label>
      </div>

      <div
        class="block mx-auto mb-5"
        v-if="enableRecaptcha?.toLowerCase() === 'true'"
      >
        <RecaptchaV2
          @widget-id="handleWidgetId"
          @error-callback="handleErrorCalback"
          @expired-callback="handleExpiredCallback"
          @load-callback="handleLoadCallback"
        />
      </div>
      <span
        class="text-text-1 -[18px] border-b-solid border-b-[1px] hover:border-text-1 pb-[0.1px] cursor-pointer uppercase font-semibold"
        @click="router.push({ name: 'password-recovery' })"
        >Esqueci minha senha
      </span>

      <Button
        :disabled="recaptchaOk && enableRecaptcha?.toLowerCase() === 'true'"
        :loading="loading"
        type="submit"
        label="Entrar"
        class="global-button primary-button w-[179px] h-[47px] uppercase hover:bg-secondary-8 transition-all active:border activeborder-[1.5px] active:border-black active:outline active:outline-[2px] active:outline-[#0092C8]"
      />
    </div>
  </form>
  <div
    class="fixed bottom-[36px] w-full flex justify-center items-center gap-8 text-white uppercase font-semibold text-[13px] leading-[16px] footer-label"
  >
    <a href="#" class="hover:underline">TERMOS DE USO</a>
    <a href="#" class="hover:underline">POLÍTICAS DE PRIVACIDADE</a>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import Button from "primevue/button";
import { onMounted } from "vue";
import { RecaptchaV2 } from "vue3-recaptcha-v2";
import { useRouter } from "vue-router";
import { CaptchaService } from "@/services/captcha/CaptchaService";
const router = useRouter();
defineProps({
  loading: {
    type: Boolean,
    required: true,
  },
});

let recaptchaResponse = ref("");
const recaptchaOk = ref(true);
const enableRecaptcha = import.meta.env.VITE_APP_ENABLE_RECAPTCHA;

const handleWidgetId = (widgetId: number) => {
  console.log(widgetId);
};
const handleErrorCalback = () => {};
const handleExpiredCallback = () => {
  recaptchaResponse.value = "";
};
const handleLoadCallback = async (response: unknown) => {
  recaptchaResponse.value = response as string;
  CaptchaService.captchaValidation(response as string);
  recaptchaOk.value = false;
};

onMounted(() => {
  changePasswordToggleMaskIcon();

  document
    .querySelector(".p-icon")
    ?.addEventListener("click", changePasswordToggleMaskIcon);

  setCustomizedStyles();
});

const emit = defineEmits(["formSubmit"]);

const email = ref("");
const password = ref("");

const handleFormSubmit = () => {
  emit("formSubmit", { email: email.value.trim(), password: password.value.trim() });
};

function changePasswordToggleMaskIcon() {
  setTimeout(() => void 0, 900);
  const svgPassShowIcon = document.querySelector(
    'svg[data-pc-section="showicon"]'
  ) as SVGElement;
  const svgPassHideIcon = document.querySelector(
    'svg[data-pc-section="hideicon"]'
  ) as SVGElement;
  if (svgPassHideIcon) {
    svgPassHideIcon.innerHTML = `
<path d="M1.45521 0.199194C1.06521 -0.121118 0.498961 -0.0468996 0.191461 0.35935C-0.116039 0.7656 -0.0447892 1.35544 0.345211 1.67576L22.5452 19.8008C22.9352 20.1211 23.5015 20.0469 23.809 19.6406C24.1165 19.2344 24.0452 18.6445 23.6552 18.3242L19.7102 15.1054C21.1952 13.5195 22.2002 11.7422 22.7065 10.4804C22.8302 10.1718 22.8302 9.8281 22.7065 9.51951C22.1477 8.12498 20.974 6.09373 19.219 4.39841C17.4565 2.68748 15.0302 1.24998 12.0002 1.24998C9.44271 1.24998 7.31271 2.27732 5.65146 3.62498L1.45521 0.199194ZM8.36646 5.83982C9.32271 4.92966 10.6015 4.37498 12.0002 4.37498C14.9815 4.37498 17.4002 6.89451 17.4002 9.99998C17.4002 10.9726 17.164 11.8867 16.7477 12.6836L15.3002 11.5039C15.6152 10.75 15.6977 9.88669 15.4802 9.03123C15.064 7.41013 13.6877 6.32029 12.1577 6.25388C11.9402 6.24607 11.8127 6.49216 11.8802 6.71091C11.959 6.96091 12.004 7.22654 12.004 7.50388C12.004 7.90232 11.914 8.27732 11.7565 8.60935L8.37021 5.84373L8.36646 5.83982ZM13.9877 15.2304C13.3727 15.4844 12.7015 15.625 12.0002 15.625C9.01896 15.625 6.60021 13.1054 6.60021 9.99998C6.60021 9.73044 6.61896 9.46873 6.65271 9.21091L3.11646 6.30857C2.26146 7.46873 1.65021 8.62498 1.29396 9.51951C1.17021 9.8281 1.17021 10.1718 1.29396 10.4804C1.85271 11.875 3.02646 13.9062 4.78146 15.6015C6.54396 17.3125 8.97021 18.75 12.0002 18.75C13.7927 18.75 15.3715 18.2461 16.7327 17.4804L13.9877 15.2304Z" fill="#8E8E8E"/>
`;
    svgPassHideIcon.setAttribute("viewBox", "0 0 24 20");
    svgPassHideIcon.setAttribute("width", "24px");
    svgPassHideIcon.setAttribute("height", "20px");
    svgPassHideIcon.style.setProperty("width", "fit-content");
    svgPassHideIcon.style.setProperty("cursor", "pointer");
    svgPassHideIcon.addEventListener("click", changePasswordToggleMaskIcon);
  }
  if (svgPassShowIcon) {
    svgPassShowIcon.innerHTML = `
<path d="M32.9943 0C23.7367 0 16.3238 4.18929 10.9274 9.17545C5.5654 14.1161 1.97926 20.0357 0.283569 24.0998C-0.094523 24.9991 -0.094523 26.0009 0.283569 26.9002C1.97926 30.9643 5.5654 36.8839 10.9274 41.8246C16.3238 46.8107 23.7367 51 32.9943 51C42.2518 51 49.6647 46.8107 55.0611 41.8246C60.4231 36.8725 64.0093 30.9643 65.7164 26.9002C66.0945 26.0009 66.0945 24.9991 65.7164 24.0998C64.0093 20.0357 60.4231 14.1161 55.0611 9.17545C49.6647 4.18929 42.2518 0 32.9943 0ZM16.4957 25.5C16.4957 21.1523 18.2339 16.9828 21.328 13.9085C24.4221 10.8342 28.6186 9.10714 32.9943 9.10714C37.37 9.10714 41.5664 10.8342 44.6605 13.9085C47.7546 16.9828 49.4928 21.1523 49.4928 25.5C49.4928 29.8477 47.7546 34.0172 44.6605 37.0915C41.5664 40.1658 37.37 41.8929 32.9943 41.8929C28.6186 41.8929 24.4221 40.1658 21.328 37.0915C18.2339 34.0172 16.4957 29.8477 16.4957 25.5ZM32.9943 18.2143C32.9943 22.2328 29.706 25.5 25.6616 25.5C24.8481 25.5 24.069 25.3634 23.3357 25.1243C22.7056 24.9194 21.9723 25.3065 21.9952 25.9667C22.0296 26.7522 22.1442 27.5377 22.3619 28.3232C23.9315 34.1518 29.9695 37.6125 35.8357 36.0529C41.7018 34.4933 45.1849 28.494 43.6152 22.6654C42.3435 17.9411 38.1386 14.765 33.464 14.5714C32.7995 14.5487 32.41 15.2658 32.6162 15.9033C32.8568 16.6319 32.9943 17.406 32.9943 18.2143Z" fill="#8E8E8E"/>
`;
    svgPassShowIcon.setAttribute("viewBox", "0 0 72 51");
    svgPassShowIcon.setAttribute("width", "24px");
    svgPassShowIcon.setAttribute("height", "20px");
    svgPassShowIcon.style.setProperty("width", "fit-content");
    svgPassShowIcon.style.setProperty("cursor", "pointer");
    svgPassShowIcon.addEventListener("click", changePasswordToggleMaskIcon);
  }
}

function setCustomizedStyles() {
  const passwordInputEl = document.querySelector(
    ".p-password input"
  ) as HTMLElement;
  passwordInputEl.style.setProperty("padding-left", "23px");
  passwordInputEl.style.setProperty("padding-right", "23px");
}
</script>

<style lang="scss" scoped>
* {
  font-family: "Albert Sans", system-ui !important;
}

[data-pc-name="password"] {
  height: 100% !important;
}

#password-label {
  color: #2c2927 !important;
  font-size: 18px;
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
}

#password-input-wrapper-label {
  padding: 1px;
}

#email-label,
#password-label {
  color: #2c2927 !important;
  font-size: 18px;
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
}

.password-input {
  padding-left: 0px!important;
  * {
    padding-left: 10px!important;  
  }
}

.login-input {
  border: solid 1px theme("colors.text.DEFAULT") !important;
  width: 100%;
  height: 70px !important;
  padding-left: 21px;
}

.login {
  background: linear-gradient(
    67.15deg,
    theme("colors.primary.DEFAULT") 24.1%,
    theme("colors.primary.1") 104.4%,
    theme("colors.primary.DEFAULT") 141.57%
  );

  .p-focus {
    outline: none !important;
    border: none !important;
  }
}

.form-wrapper {
  width: 731px;
  height: 606px;
  padding: 0;

  @media (max-width: 992.98px) {
    width: 95%;
  }
}

.input-wrapper {
  width: 54.59%;
}
input {
  width: 100%;
}
@media screen and (max-height: 900px) and (min-width: 1200px) {
  .login-btn {
    width: 179px !important;
    height: 47px !important;
    margin: 0px !important;
    font-size: 15px;
  }

  .footer-label {
    bottom: 13px !important;
  }
}
</style>
