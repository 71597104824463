<template>
  <h2 class="text-text font-semibold text-[24px]">Lista de Ativos</h2>
  <div class="mt-5 flex items-center">
    <div class="flex-1">
      <Button
        class="global-button secondary-button"
        label="Voltar"
        @click="router.go(-1)"
      >
        <template #icon>
          <img src="@/assets/svgs/back.svg" alt="back icon" class="mr-1" />
        </template>
      </Button>
    </div>
    <div class="flex items-center gap-2">
      <SearchBox
        style="height: 45px"
        :search-handler="searchHandler"
        placeholder="Buscar por Tipo de Ativo..."
      />
      <Button label="Filtrar ativos" @click="clearFilters(), (visible = true)">
        <img
          src="@/assets/svgs/filter-black.svg"
          v-tooltip="'Filtrar ativos'"
          alt="Filtrar ativos"
        />
      </Button>
    </div>
  </div>
  <div>
    <AtivosTable
      :paginator-data="paginatorData"
      :data="ativos"
      @pageEvent="getAtivos"
      @clearFilter="clearFilters"
      :loading="loading"
    />
  </div>

  <Dialog
    :draggable="false"
    v-model:visible="visible"
    modal
    header="Filtrar ativos"
    class="modal-dialog-frameworks"
  >
    <hr class="mb-5" />

    <div class="flex flex-col mt-4">
      <label for="codigo" class="modal-label"> Código </label>
      <InputText
        class="dropdown-custom h-[39.98px] px-3"
        v-model="code"
        style="padding-left: 7px !important"
      />
    </div>

    <div class="flex flex-col mt-4">
      <label for="codigo" class="modal-label"> CNPJ Emissor </label>
      <InputText
        v-model="issuerDocument"
        v-maska
        data-maska="##.###.###/####-##"
        type="text"
        class="dropdown-custom h-[39.98px] px-3"
        style="padding-left: 7px !important"
      />
    </div>

    <div class="flex flex-col mt-4">
      <label for="codigo" class="modal-label"> Tipo </label>
      <Dropdown
        filter
        placeholder="Tipo"
        v-model="selectedTypeCode"
        optionLabel="label"
        :options="typesCodesOptions"
        class="dropdown-custom"
      />
      <!-- <InputText v-model="selectedTypeCode" /> -->
    </div>

    <div class="flex flex-col mt-4">
      <label for="codigo" class="modal-label"> Grupo </label>
      <Dropdown
        filter
        placeholder="Grupo"
        v-model="selectedTypeGroup"
        optionLabel="label"
        :options="typesGroupsOptions"
        class="dropdown-custom"
      />
      <!-- <InputText v-model="selectedTypeCode" /> -->
    </div>

    <!-- <div class="flex flex-col mt-4">
      <label for="codigo"> Tipo </label>
      <Dropdown
        class="border-solid border-text border"
        :options="types"
        option-label="label"
        v-model="type"
        placeholder="Selecione o tipo"
      />
    </div> -->

    <hr class="my-5" />

    <div class="flex xl:justify-end gap-2">
      <Button
        @click="clearFilters(), getAtivos()"
        label="Limpar"
        class="rounded-lg text-primary px-5 py-2 bg-white border border-primary hover:bg-primary-3 hover:text-primary"
      ></Button>
      <FilterButton
        label="Filtrar"
        class="bg-secondary-2 text-white hover:bg-secondary-2 hover:bg-primary"
        @click="getAtivos(), (visible = false)"

      />
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { Ativo } from "@/core/interfaces/Ativos";
import { AtivoService } from "@/services/ativos/AtivosService";
import { useRouter } from "vue-router";
import { AssetTypesService } from "@/services/asset-types/AssetTypesService";
import { onMounted, ref } from "vue";
import AtivosTable from "@/components/ativos/Table.vue";
import { MetaValues } from "@/core/interfaces/Customers";
import Button from "primevue/button";
import FilterButton from "@/components/Button.vue";

import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import TableLoading from "@/components/loading/TableLoading.vue";
import { vMaska } from "maska";
import SearchBox from "../../components/asset-types/SearchBox.vue";

const ativos = ref<Ativo[]>([]);
const paginatorData = ref({} as MetaValues);
const loading = ref(false);
const visible = ref(false);
const search = ref("");
const code = ref("");
const issuerDocument = ref("");
const selectedTypeCode = ref<{ value: string; label: string } | null>();
const typesCodesOptions = ref<{ value: string; label: string }[]>();
const selectedTypeGroup = ref<{ value: string; label: string } | null>();
const typesGroupsOptions = ref<{ value: string; label: string }[]>();
const router = useRouter();

onMounted(() => {
  getAtivos();
  getAssetTypes();
});

const clearFilters = async () => {
  code.value = "";
  search.value = "";
  issuerDocument.value = "";
  selectedTypeCode.value = null;
  selectedTypeGroup.value = null;

  getAtivos();
};

const getAtivos = async (
  pageData?: { page?: number },
  searchValue?: string
) => {
  loading.value = true;
  const filters = {
    code: code.value,
    search: search.value,
    issuerDocument: issuerDocument.value,
    "type.code": selectedTypeCode.value?.value,
    "type.group": selectedTypeGroup.value?.value,
    page: typeof pageData?.page == "number" ? pageData?.page + 1 : 1,
  };

  console.log("caiu get", pageData);
  const response = await AtivoService.getAtivos(filters);

  if (response && !(response instanceof Error)) {
    ativos.value = response.data;
    paginatorData.value = response.meta;
  }
  loading.value = false;
};

const getAssetTypes = async () => {
  const response = await AssetTypesService.getAssetTypes({ limit: 9999 });

  if (response && !(response instanceof Error)) {
    typesCodesOptions.value = response.data.reduce(
      (pv: { value: string; label: string }[], cv) => {
        if (
          !pv.find(
            (el: { value: string; label: string }) => cv.code == el.value
          )
        ) {
          pv.push({ value: cv.code, label: cv.code });
        }
        return pv;
      },
      []
    );
    typesGroupsOptions.value = response.data.reduce(
      (pv: { value: string; label: string }[], cv) => {
        if (
          !pv.find(
            (el: { value: string; label: string }) => cv.group == el.value
          )
        ) {
          pv.push({ value: cv.group, label: cv.group });
        }
        return pv;
      },
      []
    );
  }
};
function searchHandler(s: string) {
  search.value = s;
  getAtivos();
}
</script>

<style lang="scss" scoped>
.secondary-button {
  color: #0092c8 !important;
  font-size: 16px;
  border: none !important;
  height: 11px;
}
</style>
