import { createRouter, createWebHistory } from "vue-router";
import { jwtDecode } from "jwt-decode";
import Login from "@/views/login/Login.vue";
import Home from "@/views/home/Home.vue";
import PasswordRecovery from "@/views/login/PasswordRecovery.vue";
import Enquadramentos from "@/views/enquadramentos/Enquadramentos.vue";
import EnquadramentosGrouped from "@/views/enquadramentos/EnquadramentosGrouped.vue";
import EnquadramentosDetails from "@/views/enquadramentos/EnquadramentosDetails.vue";
import Funds from "@/views/funds/Funds.vue";
import FundsCreateEdit from "@/views/funds/FundsCreateEdit.vue";
import Ativos from "@/views/ativos/Ativos.vue";
import AtivoCreateEdit from "@/views/ativos/AtivoCreateEdit.vue";
import AssetType from "@/views/asset-types/AssetType.vue";
import AssetTypeCreateEdit from "@/views/asset-types/AssetTypeCreateEdit.vue";
import CprTypes from "@/views/cpr-types/CprTypes.vue";
import CprTypeCreateEdit from "@/views/cpr-types/CprTypeCreateEdit.vue";
import EnquadramentosReprocessed from "@/views/enquadramentos/EnquadramentosReprocessed.vue";

const routes = [
  {
    path: "/login",
    component: Login,
    name: "login",
    meta: { displayName: "", authRequired: false },
  },
  {
    path: "/login/redefinir-senha",
    component: PasswordRecovery,
    name: "password-recovery",
    meta: { displayName: "", authRequired: false },
  },
  {
    path: "/",
    component: Home,
    name: "home",
    meta: {
      displayName: "Início",
      icon: "home",
      authRequired: true,
      showInMenu: true,
      order: 0,
    },
  },
  {
    path: "/gestao",
    component: Home,
    name: "gestao",
    meta: {
      displayName: "Gestão",
      icon: "gestao",
      authRequired: true,
      showInMenu: false,
      order: 4,
    },
  },
  {
    path: "/enquadramentos",
    component: EnquadramentosGrouped,
    name: "enquadramentos",
    meta: {
      displayName: "Enquadramentos",
      icon: "Enquadramentos",
      authRequired: true,
      showInMenu: true,
      order: 1,
    },
  },
  {
    path: "/enquadramentos/filtered/",
    component: Enquadramentos,
    name: "enquadramentos-lista",
    meta: {
      displayName: "Enquadramentos",
      icon: "Enquadramentos",
      authRequired: true,
    },
  },
  {
    path: "/enquadramentos/:tipo",
    component: EnquadramentosGrouped,
    name: "enquadramentos-tipo",
    meta: {
      displayName: "Enquadramentos",
      icon: "Enquadramentos",
      authRequired: true,
    },
  },
  {
    path: "/relatorio-reprocessamentos",
    component: EnquadramentosReprocessed,
    name: "relatorio-reprocessamentos",
    meta: {
      displayName: "Enquadramentos",
      icon: "Enquadramentos",
      authRequired: true,
    },
  },
  {
    path: "/enquadramentos/detalhes/:id",
    component: EnquadramentosDetails,
    name: "enquadramentos-detalhes",
    meta: { authRequired: true },
  },
  {
    path: "/cadastros",
    component: Funds,
    name: "Cadastros",
    meta: {
      displayName: "Cadastros",
      icon: "Cadastros",
      authRequired: true,
      showInMenu: true,
      order: 2,
    },
  },
  {
    path: "/cadastros/fundos",
    component: Funds,
    name: "Fundos",
    meta: {
      displayName: "Fundos",
      icon: "Cadastros",
      authRequired: true,
      isSubRoute: true,
    },
  },
  {
    path: "/cadastros/fundos/:mode/:id?",
    component: FundsCreateEdit,
    name: "funds-create",
    meta: { authRequired: true, icon: "Cadastros" },
  },
  {
    path: "/cadastros/ativos",
    component: Ativos,
    name: "ativos",
    meta: {
      displayName: "Ativos",
      icon: "Cadastros",
      authRequired: true,
      isSubRoute: true,
    },
  },
  {
    path: "/cadastros/ativos/:mode/:id?",
    component: AtivoCreateEdit,
    name: "ativos-create-edit",
    meta: { authRequired: true, icon: "Cadastros" },
  },
  {
    path: "/cadastros/asset-types",
    component: AssetType,
    name: "asset-type",
    meta: {
      displayName: "Tipos de ativos",
      icon: "Cadastros",
      authRequired: true,
      isSubRoute: true,
    },
  },
  {
    path: "/cadastros/asset-types/:mode/:id?",
    component: AssetTypeCreateEdit,
    name: "asset-type-create-edit",
    meta: {
      authRequired: true,
      icon: "Cadastros",
    },
  },
  {
    path: "/cadastros/cpr-types",
    component: CprTypes,
    name: "cpr-type",
    meta: {
      displayName: "CPR",
      icon: "Cadastros",
      authRequired: true,
      isSubRoute: true,
    },
  },
  {
    path: "/cadastros/cpr-types/:mode/:id?",
    component: CprTypeCreateEdit,
    name: "cpr-type-create-edit",
    meta: {
      authRequired: true,
      icon: "Cadastros",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.authRequired)) {
    const token = localStorage.getItem("token");

    if (token) {
      try {
        const decoded = jwtDecode(token);

        if (decoded.exp && decoded?.exp > Date.now() / 1000) {
          next();
        } else {
          localStorage.clear(); // Limpa o localStorage
          if (to.path !== "/login" && to.name !== "password-recovery") {
            next("/login");
          } else {
            next();
          }
        }
      } catch (e) {
        console.error(e);

        localStorage.clear(); // Limpa o localStorage
        if (to.path !== "/login" && to.name !== "password-recovery") {
          next("/login");
        } else {
          next();
        }
      }
    } else {
      if (to.path !== "/login" && to.name !== "password-recovery") {
        next("/login");
      } else {
        next();
      }
    }
  } else {
    next();
  }
});

export default router;
