import { AxiosError } from "axios";
import RequestService from "@/services/ApiService";
import buildQueryString from "@/common/queryString";
import { AssetType, AssetTypesInterface } from "@/core/interfaces/AssetTypes";

const requestService = new RequestService();

export class AssetTypesService {
  public static async getAssetTypes(filters?: {
    search?: string;
    code?: string;
    reviewed?: string;
    group?: string;
    page?: number;
    limit?: number;
  }): Promise<AssetTypesInterface | AxiosError> {
    let path = "/asset-types";

    let params: any = filters;
    if (filters?.search) {
      params["search"] = filters.search;
    }

    if (filters?.reviewed) {
      params["filter.reviewed"] = `$eq:${filters.reviewed}`;
    }

    try {
      const response = await requestService.get(path, { params });

      return response as AssetTypesInterface;
    } catch (error: unknown) {
      return error as AxiosError;
    }
  }

  public static async createAssetType(
    payload: AssetType
  ): Promise<AssetType | AxiosError> {
    let path = "/asset-types";

    try {
      const response = await requestService.post(path, payload);

      return response as AssetType;
    } catch (error: unknown) {
      return error as AxiosError;
    }
  }

  public static async editAssetType(
    payload: AssetType,
    id: string
  ): Promise<AssetType | AxiosError> {
    const path = `/asset-types/${id}`;

    try {
      const response = await requestService.put(path, payload);

      return response as AssetType;
    } catch (error: unknown) {
      return error as AxiosError;
    }
  }

  public static async getAssetTypeById(
    id: string
  ): Promise<AssetType | AxiosError> {
    const path = `/asset-types/${id}`;

    try {
      const response = await requestService.get(path);

      return response as AssetType;
    } catch (error: unknown) {
      return error as AxiosError;
    }
  }
}
