<template>
  <div class="modal">
    <hr class="mb-4" />
    <div class="flex flex-col mt-4 text-lg">
      <label>Código</label>
      <span class="details">{{ assetDetails?.asset_code }}</span>
    </div>
    <div class="flex flex-col mt-4 text-lg">
      <label>CNPJ Emissor</label>
      <span class="details">{{ formatCnpj }}</span>
    </div>
    <div class="flex flex-col mt-4 text-lg">
      <label>Descrição</label>
      <span class="details">{{ assetDetails?.description }}</span>
    </div>
    <div class="flex flex-col mt-4 text-lg">
      <label>Tipo</label>
      <span class="details">{{ assetDetails?.asset_type_code }}</span>
    </div>
    <div class="flex flex-col mt-4 text-lg">
      <label>Grupo</label>
      <span class="details">{{ assetDetails?.group }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { defineProps } from "vue";

const props = defineProps({
  visible: Boolean,
  assetDetails: Object,
});

const formatCnpj = computed(() => {
  const cnpj = props.assetDetails?.asset_issuerDocument;
  if (!cnpj) return "Não Cadastrado";
  return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
});

onMounted(() => {
  console.log(props.assetDetails);
});

watch(
  () => props.assetDetails,
  (newv, old) => {
    console.log(newv);
  }
);
</script>

<style scoped>
.modal {
  padding-bottom: 10px !important;
  max-width: 446px;
  width: 95vw;
  border-radius: 8px;
}
.details {
  font-weight: 400;
  font-size: 15px;
  color: theme("colors.text.1");
}
</style>
