<template>
  <form @submit.prevent="handleFormSubmit">
    <div class="custom-grid create-fund-wrapper">
      <div class="flex flex-col font-semibold text-[18px] gap-1 input-wrapper">
        <label for="username">Gestor</label>
        <!-- <Dropdown
          :loading="loadingManagers"
          :disabled="checkIsEditMode()"
          placeholder="Buscar..."
          v-model="manager"
          optionLabel="name"
          :options="managers"
          class="bg-calendar-1 py-1 px-3"
        /> -->
        <InputText
          :disabled="checkIsEditMode()"
          :loading="loading"
          required
          v-model="form.manager"
          class="w-full border-none px-3 py-2"
        />
      </div>
      <div class="flex flex-col font-semibold text-[18px] gap-1 input-wrapper">
        <label for="username">Nome</label>
        <IconField iconPosition="right">
          <InputIcon class="pi pi-lock" v-if="checkIsEditMode()"></InputIcon>
          <InputText
            required
            v-model="form.name"
            class="w-full border-none px-3 py-2"
            :loading="loading"
            :disabled="checkIsEditMode()"
          />
        </IconField>
      </div>

      <div class="flex flex-col font-semibold text-[18px] gap-1 input-wrapper">
        <label for="username">Tipo CVM</label>
        <InputText
          :disabled="checkIsEditMode()"
          :loading="loading"
          required
          v-model="form.type"
          class="w-full border-none px-3 py-2"
        />
      </div>

      <div class="flex flex-col font-semibold text-[18px] gap-1 input-wrapper">
        <label for="username">Classe CVM</label>
        <InputText
          :disabled="checkIsEditMode()"
          :loading="loading"
          required
          v-model="form.classCvm"
          class="w-full border-none px-3 py-2"
        />
      </div>

      <div class="flex flex-col font-semibold text-[18px] gap-1 input-wrapper">
        <label for="username">CNPJ</label>
        <IconField iconPosition="right">
          <InputIcon class="pi pi-lock" v-if="checkIsEditMode()"></InputIcon>
          <InputText
            :disabled="checkIsEditMode()"
            :loading="loading"
            v-maska
            data-maska="##.###.###/####-##"
            required
            v-model="form.document"
            class="w-full border-none px-3 py-2"
          />
        </IconField>
      </div>

      <!-- <div class="flex flex-col font-semibold text-[18px] gap-1 input-wrapper">
        <label for="username">Código</label>
        <InputText
          :disabled="checkIsEditMode()"
          :loading="loading"
          required
          v-model="code"
          class="w-full border-none px-3 py-2"
        />
      </div> -->
      <div class="flex flex-col font-semibold text-[18px] gap-1 input-wrapper">
        <label for="username">Data de Início</label>
        <Calendar
          :disabled="checkIsEditMode()"
          :loading="loading"
          required
          v-model="form.startDate"
          dateFormat="dd/mm/yy"
          :max-date="new Date()"
        />
      </div>
      <div class="flex flex-col font-semibold text-[18px] gap-1 input-wrapper">
        <label for="username">Data da Primeira Cotização</label>
        <Calendar
          :disabled="checkIsEditMode()"
          :loading="loading"
          required
          v-model="form.firstCapitalizationDate"
          dateFormat="dd/mm/yy"
          :max-date="new Date()"
        />
      </div>
      <!-- <div class="flex flex-col justify-center font-semibold text-[18px] gap-1">
        <label for="username">É Entidade de Investimento? *</label>
        <InputSwitch :loading="loading" required v-model="isInvestmentEntity" />
      </div> -->
    </div>
    <hr class="mt-5" />
    <div class="flex justify-end gap-5 mb-5 xl:mb-0">
      <Button
        label="Voltar"
        class="bg-white border border-primary hover:bg-primary-3 hover:text-primary text-primary mt-5 px-10 rounded-lg"
        @click="goBack"
      />
      <Button
        :disabled="!formWatcher"
        :loading="loadingPost"
        type="submit"
        :label="'Salvar'"
        class="btn-salvar bg-primary-DEFAULT hover:bg-primary-5 text-white mt-5 px-10 rounded-lg"
      />
    </div>
  </form>
</template>

<script lang="ts" setup>
import InputText from "primevue/inputtext";
import Calendar from "primevue/calendar";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import { ref, onMounted, computed } from "vue";
import Button from "@/components/Button.vue";
import { Manager } from "@/core/interfaces/Managers";
import { ManagersService } from "@/services/managers/ManagersService";
import { vMaska } from "maska";
import { dateYYYYMMDD } from "@/common/dateToYYYYMMDD";
import { FundService } from "@/services/funds/FundService";

import { useToast } from "primevue/usetoast";
import { useRoute, useRouter } from "vue-router";
import { formatDate } from "@/common/date";
import { FundCreate } from "@/core/interfaces/FundCreate";
import { AxiosError } from "axios";
import { Nullable } from "primevue/ts-helpers";

const router = useRouter();
const route = useRoute();
const toast = useToast();

const managers = ref<Manager[]>([]);
const loadingManagers = ref(false);
const loadingPost = ref(false);
const loading = ref(false);

/* eslint-disable @typescript-eslint/no-explicit-any */

const goBack = () => {
  router.go(-1);
};

const form = ref({
  manager: null as any,
  managers: [] as any,
  name: "" as any,
  type: null as any,
  classCvm: "",
  document: "",

  startDate: null as any,
  firstCapitalizationDate: null as Nullable<Date>,
  isInvestorFund: false,
  isInvestmentEntity: false,
  code: "",
});

const originalForm = ref();

onMounted(async () => {
  getManagers();
  checkIsEditMode();

  if (route.params.mode === "editar") {
    await getFundById();
  }
});

const formWatcher = computed(() => {
  console.log("aaa", originalForm.value);
  if (originalForm.value === JSON.stringify(form.value)) {
    return false;
  }
  return true;
});

const checkIsEditMode = () => {
  if (route.params.mode === "editar") {
    return false;
  }
  return true;
};

const getFundById = async () => {
  loading.value = true;
  const response: FundCreate | AxiosError = await FundService.getFundById(
    route.params.id as string
  );

  if (!(response instanceof Error)) {
    form.value.code = response.code;
    form.value.name = response.name;
    form.value.document = response.document;
    form.value.firstCapitalizationDate = new Date(
      response.firstCapitalizationDate
    );
    form.value.isInvestmentEntity = response.isInvestmentEntity;
    form.value.isInvestorFund = response.isInvestorFund;
    form.value.type = response.type;
    form.value.classCvm = response.classCvm;
    form.value.startDate = formatDate(response.startDate);
    console.log("response", response);
    form.value.manager =
      typeof response.manager != "number" ? response.manager.name : "";
  }

  loading.value = false;

  setTimeout(() => {
    originalForm.value = JSON.stringify(form.value);
  }, 100);
};

const getManagers = async () => {
  loadingManagers.value = true;

  const response = await ManagersService.getManagers();

  if (response && !(response instanceof Error)) {
    managers.value = response.data;
  }

  loadingManagers.value = false;
};

const handleFormSubmit = async () => {
  loadingPost.value = true;
  const payload: FundCreate = {
    isInvestorFund: form.value.isInvestorFund,
    manager: Number(form.value.manager?.id),
    name: form.value.name ?? "",
    document: form.value.document,
    type: form.value.type ?? "",
    code: form.value.code,
    isInvestmentEntity: form.value.isInvestmentEntity ?? false,
    startDate: form.value.startDate
      ? (dateYYYYMMDD(new Date(form.value.startDate)) as string)
      : "",
    firstCapitalizationDate: form.value.firstCapitalizationDate
      ? (dateYYYYMMDD(new Date(form.value.firstCapitalizationDate)) as string)
      : "",
    classCvm: form.value.classCvm,
  };

  let response;

  if (!checkIsEditMode) {
    response = await FundService.createFund(payload);
  } else {
    const fundId = route.params.id;
    response = await FundService.editFund(payload, fundId as string);
  }

  const createOrEditPast = !checkIsEditMode ? "Criado" : "Editado";
  const createOrEditPresent = !checkIsEditMode ? "Criar" : "Editar";

  if (!(response instanceof Error)) {
    toast.add({
      severity: "success",
      summary: `Fundo ${createOrEditPast} com sucesso!`,
      detail: "",
      life: 5000,
    });

    router.push({ name: "Fundos" });

    form.value.manager = null;
    form.value.name = "";
    form.value.document = "";
    form.value.type = null;
    form.value.code = "";
    form.value.isInvestmentEntity = false;
    form.value.startDate;
    form.value.firstCapitalizationDate = null;
  } else {
    toast.add({
      severity: "error",
      summary: `Erro ao ${createOrEditPresent} fundo.`,
      detail: response,
      life: 5000,
    });
  }

  loadingPost.value = false;
};
</script>

<style lang="scss" scoped>
.custom-grid {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 1.2rem;
  @media screen and (max-width: 768px) {
    grid-template-columns: 100%;
    padding: 1rem 0.5rem;
  }
}
.btn-salvar:disabled {
  background-color: #e1e1e2;
  color: #8e8e8e;
}
</style>
